(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);throw new Error("Cannot find module '"+o+"'")}var f=n[o]={exports:{}};t[o][0].call(f.exports,function(e){var n=t[o][1][e];return s(n?n:e)},f,f.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
(function ($) {
	require('./../../template/block/slider/script');
	require('./../../template/block/testimonial-section/script');
	require('./../../template/block/video-section/video');
	require('./../../template/block/content-slider/script');
	require('./../../template/block/contact/script');
	require('./../../template/block/top-image/script');
	require('./../../template/block/big-reviews/script');
    // Empty Space px to em

 

    function emptySpaceToEm() {
    	var windowWidth = $(window).width(); 
    	if (windowWidth >= 1200) { 
    		var rem = (parseFloat(1920 / windowWidth) * parseFloat($('html').css('font-size')));
    	} else if (windowWidth <= 1200 && windowWidth >= 768) {
    		var rem = parseFloat($('html').css('font-size')) * 1.333;
    	} else {
    		var rem = parseFloat($('html').css('font-size'));
    	}
    	console.log(rem);
    	$('.es__js').each(function () {
    		var px = parseFloat($(this).data('height'));
    		var em = ((px / rem).toFixed(1)) + 'rem';
    		$(this).css({ 'height': em });
    	});
    };
    $(document).ready(function () {
    	$("#button").click(function () {
    		$([document.documentElement, document.body]).animate({
    			scrollTop: $("#kontakta").offset().top
    		}, 2000);
    	});
    	$('.wpcf7-select').selectric();
    	$('option').each(function () {
    		$(this).attr('id', 'option');
    	})
    	$('.post-password-form p label input').attr('placeholder', 'Enter password here');



    	emptySpaceToEm();

        // $(function () {

        //     $('h1').each(function (i, el) {
        //         var c = $(el).attr("class");
        //         if (i === 0) {
        //             $(el).replaceWith('<h1 class="' + c + '">' + $(el).html() + '</h1>')
        //         } else {
        //             $(el).replaceWith('<h2 class="' + c + '">' + $(el).html() + '</h2>')
        //         }
        //     });
        // });



        $('.header__burger-button').click(function () {
        	var section = $('.header__menu');
        	var nav = $('.header__menu-list');
        	section.toggleClass('open');
        	nav.toggleClass('header__menu-list--mobile');
        	nav.toggleClass('header__menu-list--desktop');
        	$('.open-menu-section').toggleClass('open-menu-section--active');
        	$('.header__burger-button').toggleClass('header__burger-button--active');

        })
        $('.header__menu > .header__menu-list > .menu-item-has-children > a > .menu-item-has-children__arrow')
        .click(function (event) {
        	event.preventDefault();
        	event.stopPropagation();
        	$(this).parent().next('.sub-menu').slideToggle();
        	$(this).toggleClass('arrow-open');


        });
        $('.header__menu > .header__menu-list > .menu-item-has-children >.sub-menu > .menu-item-has-children > a > .menu-item-has-children__arrow')
        .click(function (event) {
        	event.preventDefault();
        	event.stopPropagation();
        	$(this).parent().next('.sub-menu').slideToggle();
        	$(this).toggleClass('arrow-open');


        });
        function carouselHeight() {
        	var headerHeight = $('header').innerHeight();
        	if ($(window).width() > 767) {
        		$('.page-container').css("padding-top", headerHeight - 5);
        	} else {
        		$('.page-container').css("padding-top", headerHeight);
        	}
        	console.log(headerHeight);
        	console.log('hi');
        };
        $(document).ready(carouselHeight);
        $(window).resize(carouselHeight);

				if( $('#application-modal').length ){

					var removeActiveModal = function(){
						$('.modal').removeClass('modal--active')
						$(document.body).removeClass('freeze-body')
					}

					$(document).on('click', function(e){
						if( $(e.target).closest('.modal__box').length < 1 && ! $(e.target).hasClass('trigger-button')){
							removeActiveModal()
						}
					})

					$('#application-modal').on('click', function(e){
						e.preventDefault();
						$('.modal[data-modal="application-modal"]').addClass('modal--active')
						$(document.body).addClass('freeze-body')
					})

					$('.modal').on('click', '.modal__close', function(e){
						e.preventDefault();
						removeActiveModal();
					})


				}
    });

    $(window).on('scroll', function () {
    	var scroll = $(window).scrollTop();
    	if (scroll >= 100) {
    		$(".header").addClass("active");
    	} else {
    		$(".header").removeClass("active");
    	}
    });


    $(window).on('load', function () {

    });

    $(window).resize(function () {
    	if ($(window).width() > 767) {

    		if ($('.header__menu').hasClass('open')) {
    			$('.header__menu').toggleClass('open')
    		}
    		if ($('.header__menu-list').hasClass('header__menu-list--mobile')) {
    			$('.header__menu-list').toggleClass('header__menu-list--mobile');
    			$('.header__menu-list').toggleClass('header__menu-list--desktop');
    		}
    		if ($('.header__menu > .header__menu-list > .menu-item-has-children > a > .menu-item-has-children__arrow').hasClass('arrow-open')) {
    			$(this).parent().next('.sub-menu').slideToggle();
    		}
    		if ($('.header__burger-button').hasClass('header__burger-button--active')) {
    			$('.header__burger-button').removeClass('header__burger-button--active')
    		}
    	}

    });

})(jQuery);
},{"./../../template/block/big-reviews/script":2,"./../../template/block/contact/script":3,"./../../template/block/content-slider/script":4,"./../../template/block/slider/script":5,"./../../template/block/testimonial-section/script":6,"./../../template/block/top-image/script":7,"./../../template/block/video-section/video":8}],2:[function(require,module,exports){
document.addEventListener('DOMContentLoaded', function() {
  var owl = $(".big-reviews-carousel").owlCarousel(
      {
          items: 1,
          autoWidth: false,
          loop: true,
          autoplay: false,
          autoplayHoverPause: true,
          dots: false,
          nav: true,
      }
  );

  $('.owl-carousel__next').click(function() { owl.trigger('next.owl.carousel') });
  $('.owl-carousel__prev').click(function() { owl.trigger('prev.owl.carousel') });
});  
},{}],3:[function(require,module,exports){
document.addEventListener('wpcf7mailsent', function (event) {
    location = '/tack/';
}, false);
},{}],4:[function(require,module,exports){
if ($('section').hasClass('slider')) {
    $(document).ready(function () {
        $(".owl-carousel").owlCarousel(
            {
                items: 1,
                autoWidth: false,
                loop: true,
                autoplay: false,
                autoplayHoverPause: true,
                dots: false,
                nav: false,

                responsive: {
                    768: {
                        nav: true
                    }
                }
            }
        );
        document.querySelector('.owl-nav> .owl-prev>span').textContent = "";
        document.querySelector('.owl-nav> .owl-next>span').textContent = "";
    });
}

},{}],5:[function(require,module,exports){
if ($('section').hasClass('slider-section')) {
    $(document).ready(function () {
        $(".owl-carousel").owlCarousel(
            {
                items: 1,
                autoWidth: false,
                loop: false,
                autoplay: false,
                autoplayHoverPause: true,
                dots: true,
                nav: false,

                responsive: {
                    768: {
                        nav: true,
                        items: 1
                    }
                }
            }
        );
        document.querySelector('.owl-nav> .owl-prev>span').textContent = "";
        document.querySelector('.owl-nav> .owl-next>span').textContent = "";
    });
}

},{}],6:[function(require,module,exports){
if ($('section').hasClass('testimonial-section')) {
    $(document).ready(function () {
        $(".owl-carousel").owlCarousel(
        {
            items: 1,
            autoWidth: false,
            loop: false,
            autoplay: false,
            autoplayHoverPause: true,
            dots: false,
            nav: true,
            autoHeight : true,
            autoplay : true ,
            autoplayTimeout : 700000 ,
            navText : [$('.testimonial-section-slider').data('prev'),$('.testimonial-section-slider').data('next')],
                // responsive: {
                //     768: {
                //         nav: true,
                //         items: 1
                //     }
                // }
            }
            );
        // document.querySelector('.owl-nav> .owl-prev>span').textContent = "next";
        // document.querySelector('.owl-nav> .owl-next>span').textContent = "next";
    });
}

},{}],7:[function(require,module,exports){
$(document).ready(function () {
    $('.top-image__scroll').click(function () {
        $('.top-image__scroll').removeAttr("href");
        var cls = $(this).closest("section").next().offset().top;
        $("html, body").animate({ scrollTop: cls }, "slow");
    })
});

$(document).ready(function() {
    $(".toggle-btn.wiecej").click(function() {
        $(".top-image__description").toggleClass("all_text");
    });
});

$('.toggle-btn.wiecej').on('click', function () {
    var $elem = $(".toggle-btn.wiecej");
    $elem.text().trim() === 'Läs mer' ? $elem.text('Mindre')
                                     : $elem.text('Läs mer');
});
},{}],8:[function(require,module,exports){
(function ($) {
    $('a.fancybox-youtube').fancybox({
        youtube: {
            maxWidth: 600,
            maxHeight: 400,
            fitToView: false,
            width: '50%',
            height: '50%',
            autoSize: false,
            closeClick: false,
            openEffect: 'slide',
            closeEffect: 'none'
        },
        vimeo: {
            color: 'f00'
        }
    });


})(jQuery);
},{}]},{},[1])